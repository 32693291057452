body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


@font-face {
  font-family: "AlexBrush";
  src: local("AlexBrush"),
    url("./Fonts/AlexBrush/AlexBrush-Regular.ttf") format("truetype");
  font-weight: bold;
}

