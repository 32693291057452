.documentList, .documentShowcase{
  text-align: center;
  background-color: white;
  padding: 10px;

 height: auto;
 margin-bottom: 10px;
}
.documentShowcase{
 border: 0;
}
.documentShowcase img{
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}
.documentList .documentTitle{
  width: 80%;
  margin: 0 auto;
  font-size: 12px;
  word-break: break-word;
}
.documentList {
 border: 0;
 padding: 0;
}
.labelsList{
  border: 1px solid lightgray;
}
.documentSelected{
  background-color: #1976d22e !important;
}
.bm{
  width: 5px;
  margin-left: -10px;
  margin-right: 10px;
}
.docImage{
  width: 70%; 
  padding: 8px; 
  cursor: pointer; 
}
.componentItem{
  border-radius: 5px;
  border: 1px solid lightgray;
  margin-bottom: 8px;
  text-align: left !important;

}

.number{
  margin-left: 10px;
  font-weight: bold;
}
.documentList > div{
  margin-bottom: 10px;
  -moz-box-shadow: 0 0 2px #ccc;
  -webkit-box-shadow: 0 0 2px #ccc;
  width: 100%;
}
.documentList img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.table{
  width: 100%;
  margin-top: 20px;
}

.table thead th{
  text-align: center;
}


.table tbody td{
  text-align: center;
}

.table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.table thead tr {
  background-color: #1976d2;
  color: #ffffff;
  text-align: left;
}
.table th,
.table td {
    padding: 12px 15px;
}
.table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.table tbody tr:last-of-type {
  border-bottom: 2px solid #1976d2;
}
.errorMessage{
  margin-top: 10px;
  padding: 10px;
  color: red;
}
.swal2-container {
  z-index: 99999;
}
.buttonForm{
 width:90%;
 margin-bottom: 10px !important; 
 text-align: left; 
 background-color: #fff;
 justify-content: left !important; 
 font-size: 10px !important;
}
.element{
  display: flex;
}
.highlighted{
  box-shadow: 0 0 4px red;
  cursor: pointer;
}
