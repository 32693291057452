.documentList,
.documentShowcase {
  text-align: center;
  background-color: white;
  padding: 10px;
  /* box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%); */
  border: 1px solid lightgray;
  height: auto;
  padding-bottom: 60px;
}
.documentShowcase img {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.documentList .documentTitle {
  width: 80%;
  margin: 0 auto;
  font-size: 12px;
  word-break: break-word;
}
.labelsList {
  border: 1px solid lightgray;
}
.documentSelected {
  background-color: #1976d22e !important;
}
.bm {
  width: 5px;
  margin-left: -10px;
  margin-right: 10px;
}
.docImage {
  width: 70%;
  padding: 8px;
  cursor: pointer;
}
.number {
  margin-left: 10px;
  font-weight: bold;
}
.documentList>div {
  margin-bottom: 10px;
  -moz-box-shadow: 0 0 10px #ccc;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
  padding: 10px;
}
.table {
  width: 100%;
  margin-top: 20px;
}
.table thead th {
  text-align: center;
}
.table tbody td {
  text-align: center;
}
.table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.table thead tr {
  background-color: #1976d2;
  color: #ffffff;
  text-align: left;
}
.table th,
.table td {
  padding: 12px 15px;
}
.table tbody tr {
  border-bottom: 1px solid #dddddd;
}
.table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
.table tbody tr:last-of-type {
  border-bottom: 2px solid #1976d2;
}
.errorMessage {
  margin-top: 10px;
  padding: 10px;
  color: red;
}
/**CSS for labeling**/
.rowClear {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.templateLabeling {
  background-color: #fff;
  overflow-y: auto;
  height: 100%;
  position: relative;
  overflow-x: hidden;
}
.attributeLabeling {
  background-color: #fff;
  overflow-y: auto;
  height: 100%;
}
.Labeling {
  background-color: #fff;
}
.newTemplate {
  width: 100%;
  background-color: #006ac6 !important;
  margin: 15px auto !important;
  text-align: center;
  display: block;
  line-height: 1 !important;
}
.optionMenu {
  float: left;
  width: 50%;
  height: 40px;
  background-color: #607d8b;
  text-align: center;
  padding-top: 9px;
  color: #fff;
  cursor: pointer;
  margin: 0;
}
.optionWrapper {
  position: fixed;
  bottom: 0;
  width: 14%;
  overflow-x: hidden;
}
.deleteAttribute {
  right: -28px;
  position: absolute;
  color: red;
  top: -12px;
  cursor: pointer;
}
.wrapperAside {
  height: calc(100vh - 144px);
  overflow-y: auto;
}
.wrapperTools {
  height: auto;
  overflow-y: auto;
}
.ltLabelUnselected {
  margin-top: 0px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
  width: 100%;
  border: none !important;
  padding: 4px !important;
  padding-left: 10px !important;
}
.ltLabelSelected {
  margin-top: 0px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
  background-color: rgba(253, 255, 50, 50%) !important;
  width: 100%;
  border: none !important;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  padding: 4px !important;
  padding-left: 10px !important;
}
.backBtn{
  margin: 4px 0 0 0 ;
  padding-left: 10px;
}