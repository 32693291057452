.MuiOutlinedInput-root{
  /*height: 59px !important;*/
  /*padding-top: 15px;*/
}

.baseSearch {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    height: 58px;

    & img {
        width: 18px;
        height: 18px;
        margin: 19px 16px 0 16px;
        position: absolute;
        left: 0;
    }

    & input {
        padding-left: 51px;
        font-style: italic;
        font-size: 16px;
        padding-top: 5px;
        height: 26px;
        margin-top: 13px;
    }


    ::-webkit-input-placeholder { /* Edge */
        color: #006ac6;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #006ac6;
    }

    ::placeholder {
        color: #006ac6;
    }
}